import React, { useState } from "react";
import { MDBContainer, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import {Link,useHistory } from "react-router-dom";

import {registerUser} from '../Utils.js'



function Register() {
  const history = useHistory();
  const [data, setData] = useState({
    first: "",
    last: "",
    email: "",
    mobile: "",
    password: "",
    _c_password: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    if(!validateFields()){
      alert('Password and Confirm Password Must be Same...')
      return;
    }

    setData({...data,_c_password : '' });
    const reg = await registerUser(data);


    if(reg){
      alert('Registration Succsfull.');
      setData({
        first: "",
        last: "",
        email: "",
        mobile: "",
        password: "",
        _c_password: ""
      });

      history.push('/login');

    } else{
      alert('Registration failed.');
    } 

  };








  const validateFields = () => data.password === data._c_password ? true : false;
  

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
      <div className="text-center mb-3">
        <h1>Register Page</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <MDBInput
          wrapperClass="mb-4"
          label="First Name"
          name="first"
          type="text"
          value={data.first}
          onChange={handleInputChange}
          required
        />
        <MDBInput
          wrapperClass="mb-4"
          label="Last Name"
          name="last"
          type="text"
          value={data.last}
          onChange={handleInputChange}
          required
        />
   
        <MDBInput
          wrapperClass="mb-4"
          label="Email"
          name="email"
          type="email"
          value={data.email}
          onChange={handleInputChange}
          required
        />
        <MDBInput
          wrapperClass="mb-4"
          label="Password"
          name="password"
          type="password"
          value={data.password}
          onChange={handleInputChange}
          required
        />
        <MDBInput
          wrapperClass="mb-4"
          label="Confirm Password"
          name="_c_password"
          type="password"
          value={data._c_password}
          onChange={handleInputChange}
          required
        />
        <MDBInput
          wrapperClass="mb-4"
          label="Phone"
          name="mobile"
          type="number"
          value={data.mobile}
          onChange={handleInputChange}
          required
        />
        <MDBBtn className="mb-4 w-100" type="submit">
          Register
        </MDBBtn>
      </form>

      <Link to='/login'>Login</Link>
    </MDBContainer>
  );
}

export default Register;
