import React, { useState } from 'react';
import {
  MDBContainer,
  MDBInput,
  MDBBtn
}
from 'mdb-react-ui-kit';
import {Link,useHistory} from "react-router-dom";
import {loginUser} from '../Utils.js'



function Login() {
  const history = useHistory();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    const login = await loginUser(data);


    if(login){
      alert('Login Succsfull.');
      setData({
        email: "",
        password: "",
      });

      localStorage.setItem('token', login.data.data);
    
      // create cookie

      history.push('/');

    } else{
      alert('Login failed.');
    } 

  };



  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">


          <div className="text-center mb-3">
            <p>Sign in with:</p>

          </div>
          <form onSubmit={handleSubmit}>
          <MDBInput wrapperClass='mb-4' name='email' label='Email address' id='form1' type='email' value={data.email}
          onChange={handleInputChange}
          required/>
          <MDBInput wrapperClass='mb-4' name='password' label='Password' id='form2' type='password' value={data.password}
          onChange={handleInputChange}
          required/>

    
      <MDBBtn className="mb-4 w-100" type="submit">
          Login
        </MDBBtn>
          

      </form>
      <p className="text-center">Not a member? <Link to='/register'>Register</Link></p>
    </MDBContainer>
  );
}

export default Login;