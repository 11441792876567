
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";




import Register from './pages/Register';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Users from './pages/Users';
import Trade from './pages/Trade';


function App() {
  return (
<Router>
      <Header/>
        <Switch>
          <Route exact path="/" component={Dashboard}/>
          <Route path="/login" component={Login}/>
          <Route path="/register" component={Register}/>
          <Route path="/users" component={Users}/>
          <Route path="/trade" component={Trade}/>
        </Switch>
     
    </Router>
  );
}

export default App;
