import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";

// import { styled } from "@mui/system";
// import { Input, inputClasses } from "@mui/base/Input";
import { runFun } from "../Utils";
const Trade = () => {
  const [formData, setFormData] = useState({
    variety: "NORMAL",
    tradingsymbol: "",
    symboltoken: "",
    transactiontype: "BUY",
    exchange: "NSE",
    ordertype: "MARKET",
    producttype: "INTRADAY",
    duration: "DAY",
    price: "",
    squareoff: "",
    stoploss: "",
    quantity: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const addNewTrade = await runFun(formData);

    if(addNewTrade){
        alert('Trade Added');
    }








  };

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const styles = {
    width: "320px",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    padding: "8px 12px",
    borderRadius: "8px",
    color: grey[900],
    background: "#fff",
    border: "0",
    boxShadow: `0px 2px 2px ${grey[300]}`,
    outline: 0,
    "&:hover": {
      borderColor: blue[400],
    },
    "&:focus": {
      outline: 0,
      borderColor: blue[400],
      boxShadow: `0 0 0 3px ${blue[600]}`,
    },
  };

  const formFields = {
    variety: "Variety",
    tradingsymbol: "Trading Symbol",
    symboltoken: "Symbol Token",
    transactiontype: "Transaction Type",
    exchange: "Exchange",
    ordertype: "Order Type",
    producttype: "Product Type",
    duration: "Duration",
    price: "Price",
    squareoff: "Squareoff",
    stoploss: "Stoploss",
    quantity: "Quantity",
  };

  const stylesDiv = {
    container: {
      display: 'flex',
      borderTop: '2px solid #fff',
      background: '#f2f2f2',
      gap: 20,
      flexDirection: 'row', // Default value
    }
  };
  
  // Media query for mobile view
  // const mediaQuery = '@media (max-width: 768px)';

  return (
    <div
    style={{
      ...stylesDiv.container,
      // [mediaQuery]: {
      //   flexDirection: 'column',
      // },
    }}
    >
      <Sidebar />
      <main style={{ flex: 6, padding: 25, marginTop: 50 }}>
        <form onSubmit={handleSubmit}>
          {Object.entries(formFields).map(([key, value]) => (
            <FormControl defaultValue="" required style={{ margin: 20 }} key={key}>
              <FormLabel>{value}</FormLabel>
              <input
                required
                style={styles}
                placeholder={`Enter ${value} here`}
                value={formData[key]}
                name={key}
                onChange={handleInputChange}
              />
              <FormHelperText />
            </FormControl>
          ))}

          <div style={{ margin: "0 auto", maxWidth: "300px", width: "100%" }}>
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                textAlign: "center",
                fontSize: "18px",
                backgroundColor: "#1976D2",
                border: "none",
                outline: "none",
                color: "#fff",
                width: "300px",
              }}
            >
              Add New Trade
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Trade;
