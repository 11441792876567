import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { getUserList,addNewKey,deleteKey,updateExitingKey } from "../Utils";

const Users = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [formData, setFormData] = useState({
    api_key : "",
    client_code : "",
    password : "",
    token : "",
  });

  const [isEditButton, setIsEditButton] = useState(false);
  const [cId, setCId] = useState('');



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = async () => {

    // user is editing the data
  
   if(isEditButton){
   
    //const updateExitingKeyD = await updateExitingKey(formData);

    //console.log(updateExitingKeyD)

    // if(updateExitingKeyD){
    //   alert('Keys Data Updated');
    //   setFormData({
    //     api_key : "",
    //     client_code : "",
    //     password : "",
    //     token : "",
    //   });
    //   setIsEditButton(false);
    //   setCId('');
    //   fetchUsersList();
    
    //   history.push('/users');
      
    // } else{
    //   alert('User update Failed.');
    // } 
   }else{
    const addNewKeyD = await addNewKey(formData);

    if(addNewKeyD){
      alert('Keys Data Inserted');
      setFormData({
        api_key : "",
        client_code : "",
        password : "",
        token : "",
      });
      setIsEditButton(false);
      setCId('');
      fetchUsersList();
    
      history.push('/users');

    } else{
      alert('User Create Failed.');
    } 
   }
   

  };







  const HeaderKeys = {

    api_key: "API Key",
    client_code: "Client Code",
    password: "Password",
    token: "Token",
  };

  useEffect(() => {

    fetchUsersList();
  }, []);



  const fetchUsersList = async () => {
    try {
      const data = await getUserList();

      setUserList(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const deleteEntry = async (id) => {
    const deleteResponse = await deleteKey(id);
    if(deleteResponse){
      alert('Data Deleted..');
      fetchUsersList();
    }else{
      alert('Data Not Deleted.')
    }

  }



  const updateShow = (id)=> {
    console.log(id);
    const tempData = userList.find(data => data.id == id);
    setFormData(tempData);
    setIsEditButton(true);
    setCId(id);

  }


  useEffect(() => {
    console.log('Is Edit Button (Updated): ' + isEditButton + ' & ID is ' + cId);
  }, [isEditButton, cId]);







  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem("token");

    if (!token) {
      // Redirect the user to the login page if token doesn't exist
      // You can use any method of redirection here (e.g., history.push)
      // Replace '/login' with the appropriate path to your login page
      history.push("/login");
    }
  }, []);

  const renderFullForm = () => {
    return (
      <TableRow style={{ background: "#d3d3d363" }}>
         <TableCell className="p-1">
          <input className={"form-control"} value={formData.api_key} name='api_key' type="text" onChange={handleInputChange} required/>
        </TableCell>
        <TableCell className="p-1">
          <input className={"form-control"} value={formData.client_code} name='client_code' type="text" onChange={handleInputChange} required/>
        </TableCell>
        <TableCell className="p-1">
          <input className={"form-control"} value={formData.password} name='password' type="text" onChange={handleInputChange} required/>
        </TableCell>
        <TableCell className="p-1">
          <input className={"form-control"} value={formData.token} name='token' type="text" onChange={handleInputChange} required/>
        </TableCell>

        <TableCell className="p-1">
          
          <Button variant="contained" color="primary" onClick={handleSubmit}>
           {
            isEditButton ? 'Update' : 'Add'
           } 
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {Object.values(HeaderKeys).map((obj) => (
            <TableCell key={obj.toString()}>{obj}</TableCell>
          ))}
           <TableCell >Action</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  // function renderCellValue(key, data, username, type) {
  //   switch (key) {
  //     case "id" : return;
  //       default:
  //       return data[key] || "N/A";
  //   }
  // }

  return (
    <div
      style={{
        display: "flex",
        borderTop: "2px solid #fff",
        background: "#f2f2f2",
        gap: 20,
      }}
    >
      <Sidebar />
      <main style={{ flex: 6, padding: 25, marginTop: 50 }}>
        <div>
          <div>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <h4>User Lists</h4>

                  <Table stickyHeader aria-label="sticky table" size="small">
                    {renderTableHead()}
                    <TableBody>
                      {userList.map((d) => (
                        <TableRow hover={true} style={{ marginBottom: 30 }}>
                          {Object.entries(HeaderKeys).map(([key, val]) => (
                            <TableCell>
                              {
                                d[key]
                              }
                            </TableCell>
                            
                          ))}
                          <TableCell>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                width: "80%",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className="p-1 text-primary cursor-pointer"
                                style={{ cursor: "pointer" }}
                              >
                                <CreateIcon style={{ width: 20, height: 20 }} onClick={() => {updateShow(d.id)}} />
                              </span>
                              <span
                                className="p-1 text-danger cursor-pointer"
                                style={{ cursor: "pointer" }}
                              >
                                <DeleteIcon style={{ width: 20, height: 20 }} onClick={() => {deleteEntry(d.id)}} />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}

                      {renderFullForm("dsfg", "salary")}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Users;
