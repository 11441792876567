import React from 'react'
import {Link} from 'react-router-dom'

const Sidebar = () => {
  return (
    <div style={{flex:1,height:'100vh',background:'#fff'}}>
      {/* <div style={{dislay:'flex', flexDirection:'column'}}> */}
      <div style={{background:'#fff', padding:'10px 15px'}}><Link to='/'>Dashboard</Link></div>
      <div style={{background:'#fff', padding:'10px 15px'}}><Link to='/users'>Users</Link></div>
      <div style={{background:'#fff', padding:'10px 15px'}}><Link to='/trade'>Trade</Link></div>
      {/* </div> */}
    </div>
  )
}

export default Sidebar
