import axios from 'axios';

// let baseURL = 'http://localhost:8000';
let baseURL = 'https://ranjit.comuniqate.com';


export const registerUser = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/regi_master`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Registration failed'); 
    }
  } catch (error) {
    console.error('Error registering user:', error);
    return false; 
  }
};
export const loginUser = async (payload) => {
  try{
    const response = await axios.post(`${baseURL}/login`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

     if (response.status === 200) {
      return response; 
    } else {
      throw new Error('Registration failed'); 
    }
  }catch(error){
    console.error('Error login user:', error);
    return false; 
  }

}
export const getUserList = async () => {
  try{
    const response = await axios.get(`${baseURL}/appkeys`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

     if (response.status === 200) {
      console.log(response.data.data)
      return response.data.data; 
    } else {
      throw new Error('Something Error.'); 
    }
  }catch(error){
    console.error('Error :', error);
    return false; 
  }

}

export const addNewKey = async (payload) => {
  try{
    const response = await axios.post(`${baseURL}/addAppKey`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    });

     if (response.status === 201) {
      return response; 
    } else {
      throw new Error('Data Not Inserted...'); 
    }
  }catch(error){
    console.error('Error inserted user:', error);
    return false; 
  }

}
export const updateExitingKey = async (payload) => {
  try{
    const response = await axios.post(`${baseURL}/addAppKey`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    });

     if (response.status === 201) {
      return response; 
    } else {
      throw new Error('Data Not Inserted...'); 
    }
  }catch(error){
    console.error('Error inserted user:', error);
    return false; 
  }

}


export const deleteKey = async (id) => {
  try{
    const response = await axios.delete(`${baseURL}/deleteAppKey/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
      return true; 
    }
  catch(error){
    console.error('Error :', error);
    return false; 
  }

 

}

export const runFun = async (payload) => {
  const newArr = [payload]
  try{
    const response = await axios.post(`${baseURL}/runFunctions`, newArr, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

     if (response.message == 'All functions executed successfully.') {
      return response; 
    } else {
      throw new Error('Trade Not Inserted...'); 
    }
  }catch(error){
    console.error('Trade inserted', error);
    return false; 
  }

}