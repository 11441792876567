import React, { useEffect } from 'react'
import {Link,useHistory} from "react-router-dom";
import Sidebar from '../components/Sidebar';
const Dashboard = () => {
  const history = useHistory();
  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      // Redirect the user to the login page if token doesn't exist
      // You can use any method of redirection here (e.g., history.push)
      // Replace '/login' with the appropriate path to your login page
      history.push('/login');
    }
  }, []);

  return (
    <div style={{display:'flex',borderTop:'2px solid #fff', background:'#f2f2f2', gap:20}}>
      <Sidebar />
      <main style={{flex:6, padding:25,marginTop:50}}>This is main</main>
    </div>


  )
}

export default Dashboard
